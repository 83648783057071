.fleet-page .fleet-filters {
  background-color: #EDECE3;
  margin-bottom: 13px;

  & .fleet-filters-line {
    width: 100%;
    height: 1px;
    opacity: 0.1;
    background-color: #000000;
  }

  & .fleet-filters-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .fleet-header {
      font: 600 68px/82px Inter;
      letter-spacing: -0.07em;
    }

    & .fleet-header-buttons {
      display: flex;
      align-items: center;
      font: 500 18px/14px Inter;
      letter-spacing: -0.05em;

      & img {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 900px) {

  .fleet-page .fleet-filters .fleet-filters-wrapper .fleet-header {
    font: 600 54px/65px Inter;
  }
}

@media (max-width: 700px) {

  .fleet-page .fleet-filters {
    margin-bottom: 24px;

    & .fleet-filters-line {
      display: none;
    }

    & .fleet-filters-wrapper {
      margin-top: 30px;
      display: block;

      & .fleet-header {
        font: 700 31px/32px Inter;
        letter-spacing: -0.05em;
      }

      & .fleet-header-buttons {
        margin-top: 24px;
      }
    }
  }
}