.fleet-page .fleet-show {
  display: flex;
  flex-wrap: wrap;

  & .fleet-show-seats {
    background: white;
  }

  & .modal-window {
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  & .fleet-plane-card {
    box-sizing: border-box;
    width: calc(50% - 15px);
    min-height: 700px;
    background-color: #FFFFFF;
    border-radius: 24px;
    margin-left: 15px;
    margin-top: 32px;
    padding: 32px 32px 24px 32px;

    & .fleet-plane-card-header {
      font: 800 48px/48px Inter;
      letter-spacing: -0.05em;
      margin-bottom: 12px;
    }

    & .fleet-plane-card-shortinfo {
      font: 600 24px/29px Inter;
      letter-spacing: -0.05em;
      margin-bottom: 32px;
    }

    & .fleet-gallery {
      display: flex;
      height: 200px;
      width: calc(100% + 32px);
      max-width: calc(100% + 32px);
      margin-right: -32px;
      margin-bottom: 32px;
      overflow: scroll;

      &::-webkit-scrollbar {
        height: 0;
        width: 0;
      }

      & img {
        height: 200px;
        margin-right: 16px;
        border-radius: 12px;
      }
    }

    & .fleet-gallery-control {
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      margin-top: -145px;
      padding-left: 32px;
      z-index: 5;

      & span {
        cursor: pointer;
      }
    }
  }

  & .fleet-plane-card-margin {
    margin-left: 0;
    margin-right: 15px;
  }

  & .fleet-card-specs {
    margin-top: 100px;

    & .fleet-card-specs-label {
      font: 600 24px/29px Inter;
      letter-spacing: -0.05em;
    }
  }

  & .fleet-card-specs-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 25px;

    & .fleet-card-specs-item {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      width: 50%;
      font: 500 18px/20px Inter;
      letter-spacing: -0.05em;
      margin-bottom: 25px;
      padding-left: 12px;

      & .fleet-card-specs-item-label {
        max-width: 180px;
      }

      & .fleet-card-specs-item-digit {
        min-width: 70px;
        margin-left: 10px;
        text-align: right;
      }
    }

    & .fleet-card-specs-item-left {
      padding-right: 12px;
      padding-left: 0;
    }
  }

  & .fleet-card-bottom {

    & .fleet-card-bottom-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: 100%;
      border-radius: 6px;
      transform: skew(-3.5deg);
      background: rgba(0, 0, 0, 0.2);
      cursor: pointer;

      & .fleet-card-bottom-button-content {
        transform: skew(3.5deg);
        font: 600 14px/14px Inter;
        letter-spacing: -0.02em;
      }
    }
  }
}

@media (max-width: 1300px) {

  .fleet-page .fleet-show .fleet-plane-card {

    & .fleet-plane-card-header {
      font: 800 38px/38px Inter;
    }

    & .fleet-plane-card-shortinfo {
      font: 600 19px/23px Inter;
    }
  }
}

@media (max-width: 1090px) {

  .fleet-page .fleet-show .fleet-plane-card {

    & .fleet-plane-card-header {
      height: 76px;
    }

    & .fleet-plane-card-shortinfo {
      height: 46px;
    }

    & .fleet-card-specs-grid .fleet-card-specs-item {
      font: 500 14px/16px Inter;
    }
  }
}

@media (max-width: 900px) {

  .fleet-page .fleet-show .fleet-plane-card {

    & .fleet-card-specs-grid {

      & .fleet-card-specs-item {
        font: 500 11px/12px Inter;
        padding-left: 0;

        & .fleet-card-specs-item-digit {
          min-width: 45px;
        }
      }
    }
  }
}

@media (max-width: 700px) {

  .fleet-page .fleet-show {
    display: block;
    min-height: auto;

    & .fleet-plane-card {
      width: 100%;
      min-height: auto;
      margin: 0 0 15px 0;
      padding: 24px 20px 24px 20px;

      & .fleet-plane-card-header {
        font: 700 32px/31px Inter;
        margin-bottom: 8px;
        height: auto;
      }

      & .fleet-plane-card-shortinfo {
        font: 600 20px/24px Inter;
        height: auto;
        margin-bottom: 15px;
      }

      & .fleet-gallery {
        width: calc(100% + 20px);
        max-width: calc(100% + 20px);
        margin-right: -20px;
        margin-bottom: 25px;
      }

      & .fleet-gallery-control {
        padding-left: 10px;
      }

      & .fleet-card-specs {

        & .fleet-card-specs-label {
          font: 600 20px/24px Inter;
        }
      }

      & .fleet-card-specs-grid-wrapper {
        max-width: 100%;
        overflow-x: scroll;

        &::before {
          display: block;
          margin: 0 0 -200px 0;
          content: " ";
          position: absolute;
          width: calc(100% - 30px - 30px);
          height: 200px;
          z-index: 1;
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 70%, rgb(255, 255, 255) 100%);
        }

        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }

        & .fleet-card-specs-grid {
          width: 500px;
          margin-top: 25px;

          & .fleet-card-specs-item {
            width: 200px;
            min-width: 200px;
            font: 500 14px/16px Inter;
            margin-bottom: 25px;

            & .fleet-card-specs-item-digit {
              min-width: 60px;
            }
          }
        }
      }
    }
  }
}