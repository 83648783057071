.static-page .partners-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: 80px;
  margin-bottom: 120px;

  & .partners-content-title {
    padding-top: 30px;
    font: 700 48px/48px Inter;
    letter-spacing: -0.06em;
    width: 100%;
    margin-bottom: 24px;
  }

  & .partners-content-text {
    display: flex;
    flex-wrap: wrap;
    font: 400 24px/32px Inter;
    letter-spacing: -0.05em;
    width: 50%;
  }

  & p {
    margin-bottom: 24px;
  }

  & ul {
    margin: 0;
  }

  & li {
    list-style-image: url(../assets/bullet-orange.png);
    margin-bottom: 24px;
    padding-left: 9px;
    font: 400 18px/26px Inter;
    letter-spacing: -0.03em;
  }

  & form {
    box-sizing: border-box;
    width: calc(50% - 80px);
    padding: 62px 72px;
    background: #FFFFFF;
    box-shadow: -16px 4px 132px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    & .partners-content-form-title {
      font: 700 32px/32px Inter;
      letter-spacing: -0.05em;
    }

    & .partners-content-form-caption {
      margin-top: 8px;
      font: 500 18px/24px Inter;
      letter-spacing: -0.03em;
      color: #666666;
    }

    & .partners-content-form-input-title {
      margin-top: 40px;
      font: 600 21px/25px Inter;
      letter-spacing: -0.02em;
    }

    & input {
      box-sizing: border-box;
      margin-top: 8px;
      width: 100%;
      height: 60px;
      padding: 18px 16px;
      background: #FAF7F2;
      border-radius: 8px;
      outline: none;
      border: 0;
      font: 500 20px/24px Inter;
      letter-spacing: -0.02em;

      &::placeholder {
        color: #7D7B79;
      }
    }

    & .partners-content-form-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      margin-top: 45px;
      border-radius: 8px;
      background: #F5F5F5;
      font: 600 16px/22px Inter;
      letter-spacing: -0.02em;
      color: #7A7A7A;
    }

    & .partners-content-form-button-active {
      background: #FAA81F;
      color: black;
      cursor: pointer;
    }
  }
}

@media (max-width: 1080px) {

  .static-page .partners-content .partners-content-text {
    width: 100%;
  }

  .static-page .partners-content form {
    margin-top: 6px;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .static-page .partners-content {
    margin-bottom: 50px;

    & .partners-content-title {
      padding-top: 16px;
      font: 600 28px/28px Inter;
      margin-bottom: 12px;
    }

    & .partners-content-text {
      width: 100%;
      font: 400 18px/24px Inter;

      & li {
        margin-bottom: 12px;
        padding-left: 7px;
        font: 400 14px/19px Inter;
        letter-spacing: -0.02em;
      }
    }

    form {
      margin-top: 0;
      padding: 24px;
      box-shadow: 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

      & .partners-content-form-title {
        font: 700 24px/26px Inter;
      }

      & .partners-content-form-caption {
        margin-top: 2px;
        font: 400 14px/19px Inter;
        letter-spacing: -0.02em;
      }

      & .partners-content-form-input-title {
        margin-top: 20px;
        font: 600 16px/14px Inter;
        letter-spacing: -0.03em;
      }

      & input {
        margin-top: 16px;
        height: 42px;
        padding: 10px 16px;
        font: 500 16px/22px Inter;
        letter-spacing: -0.06em;
        margin-bottom: 4px;
      }

      & .partners-content-form-button {
        height: 42px;
        margin-top: 32px;
        border-radius: 6px;
        font: 600 14px/14px Inter;
        transform: skewX(-3deg);

        & span {
          transform: skewX(3deg);
        }
      }
    }
  }
}