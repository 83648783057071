$mw: 1440px; // Максимальная ширина контента
$minw: 320px;  // Минимальная ширина контента

$hp: 80px; // Внутренние отступы
$hm: calc((100% - #{$mw}) / 2); // Расчет отступов

$part-hm: 40px; // Сокращенные отсупы на разрешении меньше 1300px
$mobi-hm: 10px; // Отсупы в мобильных на меньше 700px

.how-it-works-content {
  padding: 0;
  background-color: #EDECE3;

  & .modal-window {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  & .how-it-works-content-slide1, .how-it-works-content-slide2, .how-it-works-content-slide3, .how-it-works-content-slide4 {
    display: flex;
    box-sizing: border-box;
    padding: 0 calc(#{$hm} + #{$hp});
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    & .how-it-works-content-slide1-header {
      margin-top: 80px;
      max-width: 870px;
      font: 700 68px/64px Inter;
      letter-spacing: -0.07em;

      & .how-it-works-content-slide1-header-caption {
        width: fit-content;
        margin-top: 24px;
        padding-right: 35px;
        font: 700 24px/32px Inter;
        letter-spacing: -0.05em;
        background: url(../assets/down-arrow.svg) right center no-repeat;
      }
    }

    & .how-it-works-content-slide1-offer {
      display: flex;
      align-items: center;
      width: 1068px;
      padding: 24px 0;

      img {
        width: 100%;
      }
    }
  }

  & .how-it-works-content-slide2, .how-it-works-content-slide3, .how-it-works-content-slide4 {
    margin-top: -100px;
    padding-top: 100px;
    padding-bottom: 100px;
    background: black;
    border-radius: 16px 16px 0 0;

    & .how-it-works-content-slide2-header, .how-it-works-content-slide3-header, .how-it-works-content-slide4-header {
      font: 700 48px/48px Inter;
      letter-spacing: -0.06em;
      color: white;
      max-width: 870px;

      & .how-it-works-content-slide2-example, .how-it-works-content-slide3-header-caption {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font: 600 18px/30px Inter;
        letter-spacing: -0.04em;
        color: white;
        margin-top: 56px;

        & .how-it-works-content-slide2-example-splitter {
          width: 295px;
          height: 1px;
          background: white;
          margin-left: 21px;
        }
      }

      & .how-it-works-content-slide2-images {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 48px -150px 0 0;

        & .comics {
          cursor: pointer;
        }

        & .shema-small {
          display: none;
        }
      }
    }
  }

  & .how-it-works-content-slide3, .how-it-works-content-slide4  {
    background: #FAF7F2;
    margin-top: -50px;
    padding-bottom: 50px;

    & .how-it-works-content-slide3-header, .how-it-works-content-slide4-header {
      color: black;

      & .how-it-works-content-slide3-header-caption {
        color: black;
      }
    }

    & .how-it-works-slide3-screen {
      width: 100%;
      max-height: 650px;
      margin-top: 50px;
      text-align: center;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  & .how-it-works-content-slide4 {
    background: #EDECE3;

    & .how-it-works-content-slide4-header-caption{
      font: 600 24px/24px Inter;
      letter-spacing: -0.05em;
      margin-top: 32px;
    }
  }
}


@media (max-width: $mw) {

  & .how-it-works-content {

    & .how-it-works-content-slide1, .how-it-works-content-slide2, .how-it-works-content-slide3, .how-it-works-content-slide4 {
      padding-left: $hp;
      padding-right: $hp;
    }
  }
}

@media (max-width: 1300px) {

  & .how-it-works-content {

    & .how-it-works-content-slide1, .how-it-works-content-slide2, .how-it-works-content-slide3, .how-it-works-content-slide4 {
      padding-left: $part-hm;
      padding-right: $part-hm;
    }

    & .how-it-works-content-slide2 .how-it-works-content-slide2-header .how-it-works-content-slide2-images {
      flex-wrap: wrap;
      margin-right: 0;
      row-gap: 50px;
    }
  }
}

@media (max-width: 900px) {
  & .how-it-works-content .how-it-works-content-slide2 .how-it-works-content-slide2-header{

    & .how-it-works-content-slide2-example .how-it-works-content-slide2-example-splitter {
      display: none;
    }

    img {
      max-width: 100%;
    }
  }
}

@media (max-width: 700px) {
  & .how-it-works-content {

    & .how-it-works-content-slide1, .how-it-works-content-slide2, .how-it-works-content-slide3, .how-it-works-content-slide4 {
      padding: 30px calc(#{$mobi-hm} + 20px) 46px;

      & .how-it-works-content-slide2-header, .how-it-works-content-slide3-header, .how-it-works-content-slide4-header {
        font: 600 28px/28px Inter;
      }
    }

    & .how-it-works-content-slide1 {
      padding-bottom: 0;

      & .how-it-works-content-slide1-header {
        font: 600 28px/28px Inter;
        margin-top: 16px;

        & .how-it-works-content-slide1-header-caption {
          font: 600 18px/22px Inter;
          padding-right: 20px;
          background: url(../assets/down-arrow.svg) right center/13px auto no-repeat;
        }
      }

      & .how-it-works-content-slide1-offer {
        padding: 0;
        margin-top: 24px;
      }
    }

    & .how-it-works-content-slide2 {
      margin-top: -16px;

      & .how-it-works-content-slide2-header {

        .how-it-works-content-slide2-example {
          margin-top: 24px;
          line-height: 20px;
        }

        & .how-it-works-content-slide2-images {
          margin-top: 24px;
          row-gap: 24px;
          column-gap: 200px;
          justify-content: center;

          & .shema {
            display: none;
          }

          & .shema-small {
            display: block;
          }
        }
      }
    }

    & .how-it-works-content-slide3 {
      margin-top: -16px;

      & .how-it-works-content-slide3-header .how-it-works-content-slide3-header-caption {
        margin-top: 24px;
        font: 600 18px/22px Inter;
        letter-spacing: -0.05em;
      }

      & .how-it-works-slide3-screen {
        margin-top: 24px;
        margin-right: -30px;
        width: calc(100% + 30px);
        overflow: scroll;

        &::-webkit-scrollbar {
          height: 0;
          width: 0;
        }

        img {
          min-width: 970px;
        }
      }
    }

    & .how-it-works-content-slide4 {

      & .how-it-works-content-slide4-header .how-it-works-content-slide4-header-caption {
        font: 600 18px/22px Inter;
        margin-top: 12px;
      }
    }
  }
}