.text-search-item {
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  text-align: center;
  margin-top: 35px;
  padding-right: 50px;
  cursor: default;
  transform: skewX(-3deg);
  border-radius: 16px;
  background: url(../assets/text-search-item-bg.png) left center/100% 100% no-repeat;

  & .text-search-item-content {
    width: 100%;
    padding: 33px;
    transform: skewX(3deg);

    & .text-search-item-header {
      font: 800 48px/52px Inter;
      letter-spacing: -0.05em;
    }

    & .text-search-item-caption {
      margin-top: 8px;
      font: 500 20px/32px Inter;
      letter-spacing: -0.03em;

      & a {
        text-decoration: underline;
      }
    }

    & .text-search-item-form {
      display: flex;
      box-sizing: border-box;
      align-items: center;
      margin-top: 12px;
      width: 545px;
      height: 59px;
      padding: 7px 7px 7px 22px;
      margin-left: auto;
      margin-right: auto;
      background: #FAF7F2;
      border-radius: 8px;
      transform: skewX(-3deg);

      & .text-search-item-form-input {
        width: 100%;
        transform: skewX(6deg);
        border: 0;
        background: #FAF7F2;
        margin-right: 10px;
        font: 500 16px/20px Inter;
        letter-spacing: -0.02em;
        color: #000000;
        outline: none;
        transform: skewX(3deg);

        &::placeholder {
          color: #646361;
        }
      }

      & .text-search-item-form-button, .text-search-item-form-button-disabled {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 140px;
        height: 100%;
        background: #FAA81F;
        border-radius: 5px;
        font: 600 14px/14px Inter;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        cursor: pointer;

        & span {
          transform: skewX(3deg);
        }
      }

      & .text-search-item-form-button-disabled {
        cursor: default;
      }
    }
  }
}

@media (max-width: 700px) {
  .text-search-item {
    text-align: left;
    margin: 10px 20px 0 20px;
    max-width: 100%;

    & .text-search-item-content {
      padding: 16px;

      & .text-search-item-header {
        font: 800 36px/37px Inter;
      }

      & .text-search-item-caption {
        margin-top: 8px;
        font: 500 18px/22px Inter;
        letter-spacing: -0.04em;
      }

      & .text-search-item-form {
        width: 100%;
        padding: 3px;
        height: 45px;
        justify-content: center;

        & .text-search-item-form-button {
          background: #000000;
        }

        & .text-search-item-form-input {

        }
      }
    }
  }
}