.filter-button {
  height: 32px;
  margin-left: 12px;
  padding: 0 10px;
  transform: skew(-15deg);
  background: #FFFFFF;
  border-radius: 5px;
  cursor: pointer;

  & .filter-button-content {
    display: flex;
    align-items: center;
    height: 100%;
    font: 600 14px/14px Inter;
    letter-spacing: -0.02em;
    transform: skew(15deg);
  }

  & .filter-button-right-img {
    padding-left: 10px;
  }

  & .filter-button-left-img {
    padding-right: 10px;
  }
}