.pax-button {
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 25px;
  background: url(../assets/pax.png) left center no-repeat;
  font: 600 14px/14px Inter;
  letter-spacing: -0.02em;

  .pax-button-item-short-label {
    display: none;
  }

  & .pax-button-item {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transform: skew(-15deg);
    height: 100%;
    width: 80px;
    border-radius: 5px;
    background: #FFFFFF;
    margin-left: 15px;

    & .pax-button-caption {
      transform: skew(15deg);
      cursor: pointer;
    }

    & .pax-button-tilde {
      height: 100%;
      width: 1px;
      background: #FAF7F2;
    }
  }
}

@media (max-width: 370px) {

  .pax-button {

    .pax-button-item-long-label {
      display: none;
    }

    .pax-button-item-short-label {
      display: block;
    }
  }
}