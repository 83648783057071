$mw: 1440px; // Максимальная ширина контента
$hm: calc((100vw - #{$mw}) / -2); // Расчет отступов

.second_screen {

  & .callbutton {
    display: none;
  }

  & .smi {

    & .top {
      display: none;
    }

    & .items {
      display: flex;
      margin: 30px -80px 0 0;
      overflow: scroll;
      position: relative;

      &::-webkit-scrollbar {
        height: 0px;
        width: 0px;
      }

      & .item {
        display: flex;
        align-items: flex-end;
        width: 249px;
        min-width: 249px;
        height: 200px;
        margin-right: 30px;
        padding: 23px 23px 23px 23px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 12px;

        & .text {
          font: 500 18px/20px Inter;
          letter-spacing: -0.05em;
        }

        & .data {
          margin-top: 8px;
          font: 500 13px/13px Inter;
          letter-spacing: -0.02em;
          opacity: 0.5;
        }
      }
    }

    & .caption {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .header {
        font: bold 48px/44px Inter;
        letter-spacing: -0.05em;
      }
    }
  }

  .articles {
    display: flex;
    margin: 60px 0 0 -100px;

    & .row {
      width: 50%;

      & .item {
        margin: 0 0 60px 100px;

        & .partner_top {
          display: flex;
          justify-content: space-between;

          & .navigation {
            display: flex;
            align-items: center;
          }
        }

        & .partner_content {
          box-sizing: border-box;
          display: flex;
          overflow: scroll;
          margin: 40px calc(#{$hm} - 80px) 0px 0px;

          &::-webkit-scrollbar {
            height: 0px;
            width: 0px;
          }

          & .partner_item {
            min-width: 292px;
            width: 292px;
            padding: 28px 24px 32px 24px;
            margin: 0 0 0 16px;
            background: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(12px);
            border-radius: 12px;

            & .header {
              margin: 52px 0 0 0;
              font: 600 20px/22px Inter;
              letter-spacing: -0.03em;
            }

            & .text {
              height: 100px;
              margin: 10px 0 0 0;
              font: 500 13px/16px Inter;
              letter-spacing: -0.02em;
              opacity: 0.6;
            }

            & .logo {
              height: 54px;

              img {
                max-width: 180px;
                max-height: 54px;
              }
            }
          }
        }

        & .line {
          background-color: #000000;
          height: 1px;
          width: 100%;
          margin-top: 7px;
        }

        & .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;
          margin-bottom: 18px;
          font: bold 48px/44px Inter;
          letter-spacing: -0.05em;
        }

        & .text {
          margin-top: 15px;
          height: 100px;
          font: 500 18px/20px Inter;
          letter-spacing: -0.05em;
        }

        & .link {
          background: url(../assets/link-arrow.png) left center no-repeat;
          padding: 3px 0 3px 28px;
          font: 600 18px/22px Inter;
          letter-spacing: -0.05em;
        }

        & .top {
          display: flex;

          & .number {
            margin-left: -20px;
            width: 20px;
            font: 600 10px/14px Inter;
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }
}

.rbc {
  background: url(../assets/rbc.png) left 23px top 23px/90px 24px no-repeat;
}

.instyle {
  background: url(../assets/instyle.png) left 23px top 23px/108px 26px no-repeat;
}

.bizavnews {
  background: url(../assets/bizav.png) left 23px top 23px/113px 28px no-repeat;
}

.sharespro {
  background: url(../assets/shield.png) left 23px top 23px/45px 45px no-repeat;
}

.charter_brocker {
  background: url(../assets/charter-broker.png) left 23px top 23px/200px no-repeat;
}

.ainonline {
  background: url(../assets/ainonline.png) left 23px top 23px/181px 32px no-repeat;
}

.business_airport {
  background: url(../assets/business-airport.png) left 23px top 23px/68px 32px no-repeat;
}

.business_air_news {
  background: url(../assets/business-air-news.png) left 23px top 23px/200px no-repeat;
}

@media (max-width: $mw) {

  .second_screen .articles .row .item .partner_content {
    margin-right: -80px;
  }
}

@media (max-width: 1300px) {

  .second_screen {

    & .articles .row .item .partner_content {
      margin-right: -40px;
    }

    & .smi .items {
      margin-right: -40px;
    }
  }
}

@media (max-width: 1090px) {

  .second_screen .articles .row .item {

   & .header {
      font: bold 36px/34px Inter;
    }

    & .text {
     height: 130px;
    }
  }
}

@media (max-width: 900px) {

  .second_screen {

    & .articles .row .item {

      & .header {
        font: bold 26px/24px Inter;
      }

      & .text {
        height: 150px;
      }
    }
  }
}

@media (max-width: 700px) {

  .second_screen {

   & .callbutton {
     display: flex;
     width: 100%;
     height: 40px;
     align-items: center;
     justify-content: center;
     background: #000000;
     font: 600 14px/14px Inter;
     letter-spacing: -0.02em;
     transform: skewX(-6deg);
     border-radius: 5px;
     color: #FFFFFF;

     & .callbutton-form {
       display: flex;
       width: 100%;
       height: 34px;
       justify-content: space-between;
       padding: 0 3px 0 10px;

       & .callbutton-input {
         width: 115px;
         height: 100%;
         outline: none;
         transform: skewX(6deg);
         background: #000000;
         color: #FFFFFF;
         border: 0;
         font: 500 14px/18px Inter;
         letter-spacing: -0.04em;
       }

       & .callbutton-input-submit {
         display: flex;
         justify-content: center;
         align-items: center;
         height: 100%;
         width: 120px;
         background: #FFFFFF;
         border-radius: 5px;

         & span {
           transform: skewX(6deg);
           color: #000000;
         }
       }
     }

     & .callbutton-header {
       transform: skewX(6deg);
     }
   }

    & .smi {

      & .caption {
        margin-top: 5px;

        & .header {
          font: bold 48px/44px Inter;
          letter-spacing: -0.05em;
        }

        & .navigation {
          display: none;
        }
      }

      & .top {
        display: flex;
        margin-top: 40px;

        & .number {
          margin-left: -20px;
          width: 20px;
          font: 600 10px/14px Inter;
          letter-spacing: -0.02em;
        }

        & .line {
          background-color: #000000;
          height: 1px;
          width: 100%;
          margin-top: 7px;
        }
      }

      & .items {
        margin-right: -30px;
      }
    }

    & .articles {
      display: block;
      margin: 40px 0 0px -0px;

      & .row {
        width: 100%;

        & .item {
          margin: 0 0 45px 0;

          & .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 18px;
            font: bold 48px/44px Inter;
            letter-spacing: -0.05em;
          }

          & .text {
            height: auto;
            font: 500 16px/17px Inter;
            letter-spacing: -0.02em;
          }

          & .link {
            margin-top: 30px;
          }

          .partner_top .navigation {
            display: none;
          }

          & .partner_content {
            display: flex;
            margin: 30px -30px 0px -15px;
          }
        }
      }
    }
  }
}