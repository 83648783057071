.header-menu {
  position: fixed;
  width: 380px;
  border-radius: 24px;
  background: #FFFFFF;
  box-shadow: -16px 12px 140px rgba(0, 0, 0, 0.25);
  padding: 32px;
  top: 35px;
  right: 10px;
  z-index: 10;

  & .header-menu-item {
    font: 700 27px/31px Inter;
    letter-spacing: -0.05em;
    margin-bottom: 32px;
  }

  & .header-menu-subitem {
    font: 600 20px/22px Inter;
    letter-spacing: -0.05em;
    margin-bottom: 32px;
  }

  & .header-menu-bottom {
    display: flex;
    padding: 0 32px;
    margin: 0 -32px;
    border-top: 1px solid #E6E6E6;

    & div {
      margin-top: 32px;
      width: 50%;
      font: 600 20px/22px Inter;
      letter-spacing: -0.05em;
    }
  }
}

@media (max-width: 700px) {
  .header-menu {
    box-sizing: border-box;
    width: 100%;
    border-radius: 24px 24px 0 0;
    top: auto;
    right: 0;
    bottom: 0;

    & .header-menu-bottom {
      display: block;

      & div {
        width: 100%;
      }
    }
  }
}