$mw: 1440px; // Максимальная ширина контента
$minw: 300px;  // Минимальная ширина контента

$hp: 80px; // Внутренние отступы
$hm: calc((100% - #{$mw}) / 2); // Расчет отступов

$part-hm: 40px; // Сокращенные отсупы на разрешении меньше 1300px
$mobi-hm: 10px; // Отсупы в мобильных на меньше 700px

.flights .filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 calc(#{$hm} + #{$hp});
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  border-width: 0 0 1px 0;
  font: 600 14px/14px Inter;
  letter-spacing: -0.02em;

  & .filters-popup-closed {
    display: none;
  }

  & .filters-popup {
    position: fixed;
    z-index: 10;
    right: 10px;
    top:30px;
    width: 390px;
    height: 720px;
    max-height: 720px;
    border-radius: 24px;
    background: #FFFFFF;
    box-shadow: -16px 12px 140px rgba(0, 0, 0, 0.25);

    & .filters-popup-sort {
      display: flex;
      flex-wrap: wrap;
      margin: 30px 15px 25px 15px;
      width: calc(100% - 30px);
      height: 130px;

      & .filters-popup-sort-item {
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 50%;
        background: linear-gradient(230.82deg, #FAF7F2 -7.1%, #FFF8EA 92.4%);
        cursor: pointer;
        font: 600 14px/14px Inter;
        letter-spacing: -0.02em;
      }

      & .filters-popup-sort-item-active {
        background: #FAA81F;
        color: #FFFFFF;
      }

      & .filters-popup-sort-item-lowprice {
        border-radius: 12px 0 0 0;
        border-right: 1px solid #ECDED3;
        border-bottom: 1px solid #ECDED3;
      }

      & .filters-popup-sort-item-hightprice {
        border-radius: 0 12px 0 0;
        border-bottom: 1px solid #ECDED3;
      }

      & .filters-popup-sort-item-year {
        border-radius: 0 0 12px 0;
      }

      & .filters-popup-sort-item-maxpax {
        border-radius: 0 0 0 12px;
        border-right: 1px solid #ECDED3;
      }
    }

    & .filters-popup-line {
      width: 100%;
      height: 1px;
      background: rgba(0, 0, 0, 0.05);
    }

    & .filters-popup-price {
      margin: 20px 15px;

      & .filters-popup-price-label {
        font: 800 24px/22px Inter;
        letter-spacing: -0.05em;
        margin-bottom: 18px;
      }

      & .price-filter-limits {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        font: 600 17px/21px Inter;
        letter-spacing: -0.03em;
      }
    }

    & .filters-popup-type {
      margin: 20px 15px 25px 15px;

      & .filters-popup-type-label {
        font: 800 24px/22px Inter;
        letter-spacing: -0.05em;
        margin-left: 10px;
        margin-bottom: 20px;
      }

      & .filters-popup-type-wrapper {
        display: flex;


        & .filters-popup-type-item {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          width: 33%;
          border-radius: 10px;
          padding: 8px;
          cursor: pointer;
        }

        & .filters-popup-type-turboprop {
          height: 95px;
          margin-right: 4px;
          background: url(../assets/turboprop.png) center top 8px no-repeat,
          linear-gradient(230.82deg, #FAF7F2 -7.1%, #FFF8EA 92.4%);
        }

        & .filters-popup-type-turboprop-active {
          background: #FAA81F url(../assets/turboprop.png) center top 8px no-repeat;
          color: #FFFFFF;
        }

        & .filters-popup-type-midsizejet {
          height: 103px;
          margin: -8px 4px 0 4px;
          background: url(../assets/midsizejet.png) center top 8px no-repeat,
          linear-gradient(230.82deg, #FAF7F2 -7.1%, #FFF8EA 92.4%);
        }

        & .filters-popup-type-midsizejet-active {
          background: #FAA81F url(../assets/midsizejet.png) center top 8px no-repeat;
          color: #FFFFFF;
        }

        & .filters-popup-type-superjet {
          height: 111px;
          margin-left: 4px;
          margin-top: -16px;
          background: url(../assets/superjet.png) center top 8px no-repeat,
          linear-gradient(230.82deg, #FAF7F2 -7.1%, #FFF8EA 92.4%);
        }

        & .filters-popup-type-superjet-active {
          background: #FAA81F url(../assets/superjet.png) center top 8px no-repeat;
          color: #FFFFFF;
        }
      }
    }

    & .price-filter-currency {
      margin: 20px 15px 0 15px;
      display: flex;

      & .price-filter-currency-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33%;
        height: 64px;
        background: linear-gradient(230.82deg, #FAF7F2 -7.1%, #FFF8EA 92.4%);
        cursor: pointer;
        font: 600 14px/14px Inter;
        letter-spacing: -0.02em;
      }

      & .price-filter-currency-item-active {
        background: #FAA81F;
        color: #FFFFFF;
      }

      & .price-filter-currency-item-rub {
        border-radius: 12px 0 0 12px;
      }

      & .price-filter-currency-item-eur {
        border-radius: 0 12px 12px 0;
      }
    }

    & .price-filter-accept-button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      background: #FAF7F2;
      font: 800 24px/22px Inter;
      letter-spacing: -0.05em;
      height: 60px;
      border-radius: 0 0 24px 24px;
      cursor: pointer;
    }
  }

  & .quantity {
    font: 700 24px/29px Inter;
    letter-spacing: -0.05em;
  }

  & .filters-buttons {
    display: flex;
  }
}

@media (max-width: $mw) {

  .flights .filters {
    padding: 0 $hp;
  }
}
@media (max-width: 1300px) {

  .flights .filters {
      padding: 0 $part-hm;
  }
}

@media (max-width: 900px) {

  .flights .filters .quantity {
    font: 700 20px/23px Inter;
  }
}

@media (max-width: 700px) {

  .flights .filters {
    justify-content: space-between;
    height: 47px;
    padding: 0 $mobi-hm+5;
    border-width: 0;

    & .quantity {
      display: none;
    }

    & .filters-popup {
      width: 100%;
      top: auto;
      left: 0;
      bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      & .price-filter-accept-button {
        border-radius: 0;
      }
    }

    & .filters-buttons {
      width: 100%;
      justify-content: space-between;

      & .return-button {
        display: none;
      }
    }
  }
}

@media (max-height: 720px) {

  .flights .filters .filters-popup {
    position: absolute;
    bottom: auto;
    top: 0;
  }
}