footer {

  & .about {
    display: flex;
    margin-bottom: 250px;

    & .header {
      font: bold 56px/53px Inter;
      letter-spacing: -0.06em;
    }

    & .text {
      margin-top: 25px;
      margin-bottom: 40px;
      max-width: 420px;
      font: 500 18px/22px Inter;
      letter-spacing: -0.02em;
      color: rgba(0, 0, 0, 0.5);
    }

    & .links {
      margin-top: 25px;
      background: url(../assets/link-arrow.png) left center no-repeat;
      padding: 3px 0 3px 28px;
      font: 600 18px/22px Inter;
      letter-spacing: -0.05em;
    }

    & .cert1 {
      width: 250px;
      background: url(../assets/cert1.png) center top/130% auto no-repeat;
    }

    & .cert2 {
      width: 250px;
      margin-left: 20px;
      margin-right: -25px;
      background: url(../assets/cert2.png) center top/130% auto no-repeat;
    }

    & .text_item {
      width: 50%;
      margin-right: 30px;
    }

    & .certificates_desktop {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      margin-bottom: -50px;
    }

    & .certificates_mobile {
      display: none;
    }
  }

  & .contacts {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: bold 43px/40px Inter;
    letter-spacing: -0.05em;

    & .mobile_appstore_links {
      display: none;
    }


  }

  & .line {
    display: none;
  }

  & .copyrights {
    margin-top: 50px;
    font: 12px/12px Inter;
    letter-spacing: -0.03em;
    opacity: 0.6;
  }

  & .last_text {
    display: flex;
    margin-top: 15px;
    font: 12px/12px Inter;
    letter-spacing: -0.03em;
    opacity: 0.3;

    a {
      text-decoration: underline;
    }

    & .left {
      width: 50%;
      padding-right: 40px;
    }

   & .right {
     width: 50%;
   }
  }
}

.search-footer {
  background: #F9F7F1;
}
.search-footer.with-margin {
  margin-bottom: 130px;
}

@media (max-width: 700px) {
  .search-footer.with-margin {
    margin-bottom: 167px;
  }
}

.static-footer {
  background: #edece3;
}

.static-footer, .search-footer {

  & .about {
    display: none;
  }
}

@media (max-width: 1300px) {

  footer {

    & .about .cert2 {
        margin-left: -10px;
    }

    & .contacts  {
      font: bold 37px/34px Inter;
    }
  }
}

@media (max-width: 1090px) {

  footer .contacts {
    font: bold 28px/26px Inter;
  }
}

@media (max-width: 900px) {

  footer .contacts {
    font: bold 18px/17px Inter;
  }
}

@media (max-width: 700px) {

  footer {

    & .about {
      display: block;
      margin-bottom: 60px;

      & .cert1 {
        width: 50%;
        max-width: 150px;
        background: url(../assets/cert1.png) center top/150% auto no-repeat;
      }

      & .cert2 {
        width: 50%;
        max-width: 150px;
        margin-left: 20px;
        background: url(../assets/cert2.png) center top/150% auto no-repeat;
      }

      & .text_item {
        width: 100%;

        & .text {
          margin-top: 15px;
          margin-bottom: 0;
          width: 100%;
          font: 500 14px/16px Inter;
          opacity: 0.6;
        }
      }

      & .header {
        font: 600 27px/28px Inter;
      }

      & .certificates_desktop {
        display: none;
      }

      & .certificates_mobile {
        display: flex;
        justify-content: center;
        height: 240px;
        margin-top: 30px;
        margin-bottom: -25px;
      }
    }

    & .contacts {
      display: block;
      font: 600 20px/22px Inter;

      & .mobile_appstore_links {
        display: block;
      }

      & .desktop_appstore_links {
        display: none;
      }

      & .text {
        margin-top: 25px;
      }
    }

    & .line {
      display: block;
      background: #000000;
      opacity: 0.1;
      height: 1px;
      margin: 30px -15px 0px -15px;
    }

    & .copyrights {
      margin-top: 15px;
      font: 12px/12px Inter;
      letter-spacing: -0.02em;
    }

    & .last_text {
      display: block;
      margin-top: 15px;
      font: 12px/14px Inter;
      letter-spacing: -0.02em;

      & .left {
        padding-right: 0;
      }
    }
  }

  .search-footer, .static-footer{
    margin-top: 0;
    padding-top: 5px;
  }
}
