.slick-prev, .slick-next {
    &::before {
        font-family: system-ui !important;
        font-size: 30px !important;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid black !important;
        color: black !important;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.slick-prev {
    &::before {
        right: 5px !important;
    }
}

.slick-list {
    padding: 10px 0 !important;
}

.slick-slide {
    height: auto !important;
}

.slick-track {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-items: stretch !important;
}

.slide-item {

    & .item-text {
        font-size: 38px;
        font-family: Inter;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: -1.9px;

        & span {
            color: #F00;
            font-family: Inter;
            font-size: 52px;
            font-weight: 600;
            line-height: 56px;
            letter-spacing: -2.6px;
          }
    }
}

.slide-item-inner {
    padding: 0 20px;
}

@media (max-width: 700px) {
    .slide-item {
        & .item-text {
            font-size: 22px;
            font-family: Inter;
            letter-spacing: -1.2px;
            line-height: 20px;

            & span {
                font-size: 36px;
                line-height: 40px;
                letter-spacing: -1.8px;
              }
        }
    }

    .slick-prev, .slick-next {
        &::before {
            font-size: 20px !important;
            width: 20px;
            height: 20px;
        }
    }

    .slick-prev {
        &::before {
            right: 0 !important;
        }
    }

}

@media (max-width: 1300px) and (min-width: 700px) {
    .slick-prev, .slick-next {
        &::before {
            font-size: 25px !important;
            width: 28px;
            height: 28px;
        }
    }

    .slick-prev {
        &::before {
            right: 0 !important;
        }
    }
}
