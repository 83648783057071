.static-page .about-content {
  display: flex;
  flex-wrap: wrap;
  column-gap: 80px;

  & .about-content-title {
    padding-top: 30px;
    font: 700 48px/48px Inter;
    letter-spacing: -0.06em;
    width: 100%;
    margin-bottom: 24px;
  }

  & .about-content-text {
    display: flex;
    flex-wrap: wrap;
    font: 400 24px/32px Inter;
    letter-spacing: -0.05em;
    width: 50%;

    & .about-content-cert1, .about-content-cert2 {
      display: inline;
      width: 224px;
      height: 260px;
      border-radius: 8px;
      margin-bottom: 80px;
      filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.07)) drop-shadow(0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802));
    }

    & .about-content-cert1 {
      margin-right: 20px;
      background: url(../assets/cert1.png) center top/165% auto no-repeat;
    }

    & .about-content-cert2 {
      margin-left: 20px;
      background: url(../assets/cert2.png) center top/160% auto no-repeat;
    }

    & p {
      margin-bottom: 24px;
    }

    & ul {
      margin: 0;
    }

    & li {
      list-style-image: url(../assets/bullet-orange.png);
      margin-bottom: 24px;
      padding-left: 9px;
      font: 400 18px/26px Inter;
      letter-spacing: -0.03em;
    }
  }

  & .about-content-images {
    width: calc(50% - 80px);

    & img {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  & .about-content-card {
    width: calc(50% - 40px);
    margin-top: 24px;
    margin-bottom: 80px;

    & .about-content-card-name {
      margin-top: 12px;
      font: 700 32px/48px Inter;
      letter-spacing: -0.05em;
    }

    & .about-content-card-text {
      font: 400 18px/26px Inter;
      letter-spacing: -0.03em;
    }

    & img {
      height: 290px;
    }
  }
}

@media (max-width: 1080px) {
  .static-page .about-content .about-content-text {

    & .about-content-cert1, .about-content-cert2 {
      display: none;
    }
  }
}

@media (max-width: 700px) {
  .static-page .about-content {

    & .about-content-title {
      padding-top: 0;
      font: 600 28px/28px Inter;
      width: 100%;
      margin-bottom: 12px;
    }

    & .about-content-text {
      width: 100%;
      font: 400 18px/24px Inter;

      & li {
        margin-bottom: 12px;
        padding-left: 7px;
        font: 400 14px/19px Inter;
        letter-spacing: -0.02em;
      }
    }

   & .about-content-images {
     display: flex;
     overflow-x: scroll;
     overflow-y: hidden;
     max-height: 50vw;
     align-items: center;
     margin: 16px -10px 0px -10px;
     width: calc(100% + 20px);
     order: -1;
     margin-bottom: 16px;

     &::-webkit-scrollbar {
       height: 0px;
       width: 0px;
     }

     & img {
       width: 100%;
       margin-bottom: 0;
       margin-right: 20px;
     }
   }

    & .about-content-card {
      width: 100%;
      margin-top: 4px;
      margin-bottom: 0;

      & .about-content-card-name {
        margin-top: 12px;
        font: 700 24px/32px Inter;
      }

      & .about-content-card-text {
        font: 400 14px/19px Inter;
        letter-spacing: -0.02em;
        margin-bottom: 24px;
      }

      & img {
        height: 200px;
      }
    }
  }
}