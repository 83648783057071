.bblock {

  & .label {
    width: 50%;
    font: 700 56px/58px Inter;
    letter-spacing: -0.06em;
    color: #E4E4E4;
  }

  & .caption {
    width: 50%;
    margin-left: 50px;

    & .header {
      margin-top: 15px;
      min-width: 220px;
      font: 600 24px/12px Inter;
      letter-spacing: -0.05em;
      color: #E9E9E9;
    }

    & .text {
      margin-top: 15px;
      margin-bottom: 40px;
      font: 500 18px/19px Inter;
      letter-spacing: -0.03em;
      color: #9C9C9C;
    }
  }
}

@media (max-width: 1090px) {

  .bblock .label {
    font: 700 44px/42px Inter;
  }
}

@media (max-width: 700px) {

  .bblock {

    & .label {
      margin-bottom: 40px;
      width: 100%;
      color: #E9E9E9;
    }

    & .caption {
      width: 100%;
      margin-left: 0;

      &.header {
        margin-bottom: 0;
        color: #E9E9E9;
      }

      &.text {
        color: #ABABAB;
      }
    }
  }

  .bbottom {
    height: 233px;
    background: url(../assets/jet-bblock.png) center top/auto 233px no-repeat;
  }
}

@media (max-width: 340px) {

  .bblock .label{
    font: 700 34px/32px Inter;
  }

}