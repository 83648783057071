.seo-faq {

  & .seo-faq-header {
    max-width: 560px;
    font: 700 48px/48px 'Inter';
    letter-spacing: -0.06em;
    margin-bottom: 40px;
  }

  & .seo-faq-content {
    display: flex;

    & .seo-faq-left-ul {
      padding-right: 25px;
    }

    & ul {
      width: 50%;
      margin: 0;
      padding-left: 20px;
      list-style-image: url(../assets/bullet-orange.png);
      font: 600 24px/24px 'Inter';
      letter-spacing: -0.05em;

      & li {
        margin-bottom: 25px;
        padding-left: 9px;
      }
    }
  }

  & .seo-faq-caption {
    margin-top: 25px;
    font: 400 13px/13px 'Inter';
    letter-spacing: -0.04em;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media (max-width: 1090px) {

  .seo-faq {

    & .seo-faq-header {
      font: 700 27px/26px 'Inter';
      letter-spacing: -0.05em;
    }

    & .seo-faq-content ul {
      font: 600 20px/22px 'Inter';
    }
  }
}

@media (max-width: 700px) {

  .seo-faq {

    & .seo-faq-content {
      flex-wrap: wrap;
      & .seo-faq-left-ul {
        padding-right: 0;
      }

      ul {
        width: 100%;
      }
    }
  }
}