.seo-bblock {

  & .seo-bblock-pictures {
    width: 50%;
    background: url(../assets/ellipse.svg) top 20% center/80% auto no-repeat;

    & img {
      width: 85%;
    }
  }
  & .seo-bblock-content {
    color: #E4E4E4;
    width: 50%;

    & .seo-bblock-header {
      font: 700 48px/48px 'Inter';
      letter-spacing: -0.06em;
    }

    & .seo-bblock-caption {
      margin-top: 24px;
      font: 600 24px/24px 'Inter';
      letter-spacing: -0.05em;
    }

    & .seo-bblock-messages {

      & ul {
        margin-top: 24px;
        padding-left: 9px;
        list-style-image: url(../assets/bullet-white.png);

        & li {
          padding-left: 7px;
          margin-bottom: 24px;
          font: 500 18px/20px 'Inter';
          letter-spacing: -0.05em;
          color: #E9E9E9;
          opacity: 0.75;
        }
      }
    }
  }
}

@media (max-width: 1090px) {

  .seo-bblock {

    & .seo-bblock-content {

      & .seo-bblock-header {
        font: 600 27px/26px 'Inter';
      }

      & .seo-bblock-caption {
        margin-top: 12px;
        font: 600 18px/18px 'Inter';
      }

      & .seo-bblock-messages {

        & ul {

          & li {
            padding-left: 10px;
            margin-bottom: 32px;
            font: 500 14px/16px 'Inter';
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {

  .seo-bblock {

    & .seo-bblock-pictures {
      width: 100%;
      margin-top: 20px;
      padding-top: 24px;
      background: url(../assets/ellipse.svg) top center/80% auto no-repeat;

      & img {
        width: 100%;
      }
    }

    & .seo-bblock-content {
      width: 100%;
    }
  }
}