header {

  & .logo {
    display: flex;
    align-items: center;

    @media (max-width: 519px) {
      flex-grow: 1;
    }

    & .hamburger_mobile {
      display: none;
      margin-right: 7px;
      cursor: pointer;
    }

    & .text {
      display: none;
      margin-left: 16px;
      margin-bottom: 3px;
      font: 500 11px/12px Inter;
      letter-spacing: -0.02em;
    }

    .full-text {
      width: 180px;

      @media (max-width: 519px) {
        display: none !important;
      }
    }

    .short-text {
      text-align: right;
      margin-right: 10px;
      margin-left: auto !important;

      @media (min-width: 520px) {
        display: none !important;
      }
    }
  }

  & .menu {
    display: flex;
    align-items: center;
    font: 500 18px/22px Inter;

    & .hamburger_desktop {
      margin-right: 30px;
      cursor: pointer;
    }

    & .contact-buttons {
      display: flex;
      gap: 13px;

      & .link-image {
        width: 30px;
      }
    }

    & .contacts {
      margin-right: 40px;
    }

    & .telephone {
      margin-right: 30px;
    }

    & .locale {
      padding: 10px 10px 10px 10px;
      background: url(../assets/locale.png) no-repeat center;
      font: bold 12px/14px Inter;
    }
  }
}

.searchHeader {

  & .logo .text {
    display: block;
  }
}

@media (max-width: 900px) {

  .searchHeader .menu .contacts {
    display: none;
  }
}

@media (max-width: 700px) {

  header {

    & .menu {
      font: 600 12px/22px Inter;

      & .hamburger_desktop {
        display: none;
      }

      & .telephone {
        margin-right: 14px;
      }

      & .contacts {
        display: none;
      }
    }

    & .logo {

      & .hamburger_mobile {
        display: block;
      }

      & .logo_icon {
        margin-top: 5px;
        height: 30px;
      }

      & .text {
        margin-left: 10px;
        margin-bottom: 0;
      }
    }
  }

  .searchHeader {

    & .menu .telephone {
      display: none;
    }
  }
}

@media (max-width: 370px) {

  .searchHeader .logo .text {
    display: none;
  }
}

@media (min-width: 701px) {
  .contact-buttons {
    margin-right: 40px;
  }
}
