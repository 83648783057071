$mw: 1440px; // Максимальная ширина контента

.static-page .error-content {

  & .error-content-header {
    font: 700 628px/628px Inter;
    letter-spacing: -0.06em;
    color: #FAA81F;
  }

  & .error-content-caption {
    padding-top: 6px;
    padding-bottom: 100px;
    max-width: 1000px;
    font: 700 48px/48px Inter;
    letter-spacing: -0.06em;

    & .error-content-caption-link {
      text-decoration: underline;
    }
  }
}

@media (max-width: $mw) {
  .static-page .error-content {

    & .error-content-header {
      font: 700 43vw/43vw Inter;
    }
  }
}

@media (max-width: 700px) {
  .static-page .error-content {

    & .error-content-header {
      font: 700 50vw/50vw Inter;
    }

    & .error-content-caption {
      padding: 25px 30px 55px;
      font: 600 28px/28px Inter;
    }
  }
}