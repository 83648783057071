$mw: 1440px; // Максимальная ширина контента
$minw: 320px;  // Минимальная ширина контента

$hp: 80px; // Внутренние отступы
$hm: calc((100% - #{$mw}) / 2); // Расчет отступов

$part-hm: 40px; // Сокращенные отсупы на разрешении меньше 1300px
$mobi-hm: 10px; // Отсупы в мобильных на меньше 700px

.under-header-line {
  height: 1px;
  margin: 0 calc(#{$hm} + 40px);
  background-color: #000000;
  opacity: 0.1;
}

@media (max-width: $mw) {

  & .under-header-line {
    margin: 0 calc(#{$hp} + 40px);
  }
}

@media (max-width: 1300px) {

  & .under-header-line {
    margin: 0 40px;
  }
}

@media (max-width: 700px) {

  & .under-header-line {
      display: none;
    }
}