.seo-search-results {

  & .seo-search-results-header {
    font: 700 52px/48px 'Inter';
    letter-spacing: -0.05em;
  }

  & .seo-search-results-caption {
    margin-top: 24px;
    font: 600 24px/26px 'Inter';
    letter-spacing: -0.05em;
    max-width: 590px;
  }

  & .flights {
    margin-top: 65px;
    background: #EDECE3;

    & .results {
      padding: 0;
    }
  }
}

@media (max-width: 1090px) {
  .seo-search-results {

    & .seo-search-results-header {
      font: 700 27px/26px 'Inter';
    }

    & .seo-search-results-caption {
      font: 600 18px/20px 'Inter';
    }
  }
}

@media (max-width: 700px) {
  .seo-search-results {

    & .seo-search-results-caption {
      margin-top: 8px;
    }

    & .flights {
      margin-top: 30px;

      & .results {
        border-radius: 12px;
        border-width: 0;
        padding: 4px 0;
        margin: 0 -30px;

        & .results-item-last .results-item-content {
          border-bottom-width: 0;
        }
      }
    }
  }
}