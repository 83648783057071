.static-page .restrictions-content {
  padding-top: 30px;
  min-height: 750px;
  background: url(../assets/map_desk.png) top -100px right/65% auto no-repeat;

  & .restrictions-content-title {
    font: 700 48px/48px Inter;
    letter-spacing: -0.06em;
    max-width: 45%;
  }

  & .restrictions-content-text {
    font: 500 18px/26px Inter;
    letter-spacing: -0.03em;
    max-width: 45%;
    margin-top: 24px;

    & p {
      margin-bottom: 26px;
    }

    & a {
      text-decoration: underline;
    }
  }
}

@media (max-width: 700px) {
  .static-page .restrictions-content {
    padding-top: 300px;
    min-height: auto;
    background: url(../assets/map_mob.png) top right/65% auto no-repeat;

    & .restrictions-content-title {
      font: 600 28px/28px Inter;
      max-width: 100%;
    }

    & .restrictions-content-text {
      font: 500 14px/19px Inter;
      letter-spacing: -0.02em;
      max-width: 100%;
      margin-top: 10px;

      & p {
        margin-bottom: 19px;
      }
    }
  }
}