.seo-jet {

  & .seo-jet-top {
    display: flex;
    width: 100%;
    justify-content: space-between;

    & .seo-jet-header {
      width: 50%;
      font: 700 52px/48px 'Inter';
      letter-spacing: -0.05em;
      padding-right: 50px;
    }

    & .seo-jet-caption {
      width: 50%;
      font: 500 18px/20px 'Inter';
      letter-spacing: -0.05em;
    }
  }

  & img {
    margin-top: 25px;
    width: 100%;
  }
}

@media (max-width: 1090px) {

  .seo-jet .seo-jet-top {

    .seo-jet-header {
      font: 700 27px/26px 'Inter';
    }

    .seo-jet-caption {
      font: 500 16px/18px 'Inter';
    }
  }
}

@media (max-width: 700px) {

  .seo-jet {

    .seo-jet-top {
      display: block;

      & .seo-jet-header {
        width: 100%;
        padding: 0;
      }

      & .seo-jet-caption {
        margin-top: 16px;
        width: 100%;
      }
    }

    & img {
      margin-top: 16px
    }
  }
}