.firstscreen .search_action {

  & .header {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 20px;
    background: url(../assets/departure.svg) left 100px center/31px 25px no-repeat;
    font: 600 21px/22px Inter;
    letter-spacing: -0.06em;

    & .mobile_close {
      display: none;
    }
  }

  & input {
    padding: 20px 20px 20px 20px;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-width: 1px 0 1px 0;
    font: 21px/22px Inter;
    letter-spacing: -0.04em;
    outline: none;

    &::placeholder {
      font: 600 18px/22px Inter;
      letter-spacing: -0.02em;
      opacity: 0.2;
    }
  }

  & .results {
    display: flex;
    flex-wrap: wrap;
    max-height: 392px;
    overflow: scroll;
  }
}

@media (max-width: 700px) {

  .firstscreen .search_action {

    & .header .mobile_close {
      display: block;
      cursor: pointer;
    }

    & .results {
      max-height: 100%;
    }
  }
}

@media (max-width: 500px) {

  .firstscreen .search_action  {

    & .input {
      font: 600 12px/15px Inter;
    }
  }
}