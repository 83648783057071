$mw: 1440px; // Максимальная ширина контента
$minw: 300px;  // Минимальная ширина контента

$hp: 85px; // Внутренние отступы
$hm: calc((100% - #{$mw}) / 2); // Расчет отступов

$part-hm: 40px; // Сокращенные отсупы на разрешении меньше 1300px
$mobi-hm: 10px; // Отсупы в мобильных на меньше 700px

.flights .results {
  padding: 25px calc(#{$hm} + #{$hp}) 70px calc(#{$hm} + #{$hp});
  z-index: 1;
}

.flights .flightinfo-results {
  display: flex;
  max-height: 115px;
  padding: 18px 0;
  margin: 0 calc(#{$hm} + #{$hp});
  position: relative;
  overflow: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
}

.flights .flightinfo-back-results {
  max-height: 190px;
}

@media (max-width: $mw) {

  .flights .results {
    padding: 25px $hp+5 70px $hp+5;
  }

  .flights .flightinfo-results {
    padding: 18px 0;
    margin: 0 $hp+5;
  }
}

@media (max-width: 1300px) {

  .flights .results {
    padding: 25px $part-hm+5 70px $part-hm+5;
  }

  .flights .flightinfo-results {
    padding: 18px 0;
    margin: 0 $part-hm+40;
  }
}

@media (max-width: 700px) {

  .flights .results {
    background-color: white;
    border-top: rgba(0, 0, 0, 0.1) solid 1px;
    padding: 8px 0 0 0;

    & .text-item-splitter {
      margin: 12px 16px 0 16px;
      border-top: 1px solid #F2F2F2;
    }
  }

  .flights .emptyleg-results {
    padding-top: 0;
  }

  .flights .flightinfo-results {
    display: none;
  }
}

