.react-calendar {
  padding: 30px 35px 65px;
  height: 350px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 8px;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  width: 100%;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  background: none;
  text-transform: capitalize;
  font: 600 15px/14px Inter;
  letter-spacing: -0.02em;
  color: #000000;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__arrow {
  font: 25px/14px Inter !important;
}
.react-calendar__month-view__weekdays {
  display: none !important;
}
.react-calendar__month-view__days {
  width: 300px;
}
.react-calendar__month-view__days__day--weekend {
  color: #F99E1E;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #A5A5A5;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 14px 6px;
  background: none;
  text-align: center;
  font: 600 15px/14px Inter;
  letter-spacing: -0.02em;
}
.react-calendar__tile:disabled {
  color: #A5A5A5;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #FAA81F;
  border-radius: 50%;
  color: white;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #FAA81F;
  border-radius: 50%;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #FAA81F;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #FAA81F;
}
