.static-page .contacts-content {
  display: flex;
  align-items: center;
  height: 800px;
  background: url(../assets/map-contacts.png) right top/auto 100% no-repeat;
  mix-blend-mode: luminosity;

  & .contacts-block {
    display: flex;
    flex-wrap: wrap;
    width: 400px;

    & .contacts-helpdesk {
      margin-top: 20px;
      width: 200px;
      font: 600 18px/22px Inter;
      letter-spacing: -0.05em;
    }

    & .contacts-label {
      display: flex;
      align-items: center;
      margin-top: 20px;
      font: 700 24px/30px Inter;
      letter-spacing: -0.05em;

      & img {
        padding-top: 5px;
        margin-left: 8px;
      }
    }

    & .secondline {
      margin-top: 0px;
    }
  }
}

@media (max-width: 700px) {
  .static-page .contacts-content {
    background-image: none;
    height: 550px;
  }
}