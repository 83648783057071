@import url('https://fonts.googleapis.com/css?family=Inter:200,300,400,500,600,700,800&display=swap');

$mw: 1440px; // Максимальная ширина контента
$minw: 300px;  // Минимальная ширина контента

$hp: 80px; // Внутренние отступы
$hm: calc((100% - #{$mw}) / 2); // Расчет отступов

$part-hm: 40px; // Сокращенные отсупы на разрешении меньше 1300px
$mobi-hm: 10px; // Отсупы в мобильных на меньше 700px


.seo-spec-items {

  & .seo-spec-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .seo-spec-navigation {
      min-width: 52px;

      & img {
        cursor: pointer;
      }
    }
    & .seo-spec-header {
      font: 700 52px/48px 'Inter';
      letter-spacing: -0.05em;
      padding-right: 50px;
    }
  }

  & .seo-spec-items-wrapper {
    display: flex;
    margin-top: 40px;
    padding-top: 25px;
    padding-left: 10px;
    box-sizing: border-box;
    max-width: 100%;
    overflow: scroll;

    &::-webkit-scrollbar {
      height: 0px;
      width: 0px;
    }

    & .seo-spec-item-wrapper {
      transform: skew(-3.5deg);
      margin-right: 25px;
      min-width: 315px;
      background: #ECDED3;
      border-radius: 16px;

      & .seo-spec-item {
        text-align: center;
        transform: skew(3.5deg);
        margin-top: -25px;

        & img {
          height: 100px;
        }

        & .seo-spec-item-type {
          margin-top: 24px;
          font: 500 9px/13px 'Inter';
          letter-spacing: 0.15em;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.5);
        }

        & .seo-spec-item-destination {
          margin-top: 8px;
          font: 800 21px/22px 'Inter';
          letter-spacing: -0.04em;
        }

        & .seo-spec-item-price {
          margin-top: 12px;
          font: 400 21px/22px 'Inter';
          letter-spacing: -0.04em;
        }

        & .seo-spec-item-button {
          transform: skew(-3.5deg);
          background: #FAA81F;
          border-radius: 6px;
          height: 42px;
          margin: 29px 35px 22px 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          & span {
            transform: skew(3.5deg);
            font: 600 14px/14px 'Inter';
            letter-spacing: -0.02em;
          }
        }
      }
    }
  }
}


@media (max-width: $mw) {

  .seo-spec-items .seo-spec-items-wrapper {
    max-width: none;
    margin-right: -$hp;
  }
}

@media (max-width: 1300px) {

  .seo-spec-items .seo-spec-items-wrapper {
    margin-right: -$part-hm;
  }
}

@media (max-width: 1090px) {

  .seo-spec-items .seo-spec-top .seo-spec-header {
    font: 700 27px/26px 'Inter';
  }
}

@media (max-width: 700px) {

  .seo-spec-items {

    & .seo-spec-top {

      & .seo-spec-header {
        padding-right: 0;
      }

      & .seo-spec-navigation {
        display: none;
      }
    }

    & .seo-spec-items-wrapper {
      margin-top: 12px;
      margin-right: (-$mobi-hm)-20;
    }
  }
}