.book {

  & .book-form {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    right: 0;
    height: 100%;
    padding: 50px 72px;
    background: white;
    box-shadow: -16px 4px 132px rgba(0, 0, 0, 0.25);
    z-index: 15;
    overflow: scroll;

    & .book-form-image {
      margin-bottom: 8px;
    }

    & .book-form-header {
      font: 700 32px/39px Inter;
      letter-spacing: -0.05em;
    }

    & .book-form-input-caption {
      margin-top: 25px;
      font: 600 21px/25px Inter;
      letter-spacing: -0.02em;
    }

    & .book-form-input {
      box-sizing: border-box;
      margin-top: 8px;
      padding: 18px 16px;
      width: 430px;
      background: #FAF7F2;
      border-radius: 8px;
      outline: none;
      border: 0;
      font: 500 20px/24px Inter;
      letter-spacing: -0.02em;
    }

    & .book-form-text,
    & .book-form-rules {
      font-family: Inter;
      line-height: 1.55;
      max-width: 430px;
      margin-top: 25px;
      letter-spacing: -0.03em;
      color: #666666;
    }

    & .book-form-text {
      font-size: 18px;
      font-weight: 500;
    }

    & .book-form-rules {
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      gap: 10px;

      input {
        margin: 5px 0 0;
      }

      a {
        text-decoration: underline;
      }
    }

    & .book-form-text-confirm {
      margin-top: 8px;
      max-width: 430px;
      font: 500 16px/24px Inter;
      letter-spacing: -0.02em;
      color: #808080;

      & b {
        color: black;
        cursor: pointer;
      }
    }

    & .book-form-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 25px;
      height: 60px;
      width: 430px;
      background: #F5F5F5;
      font: 600 16px/22px Inter;
      letter-spacing: -0.02em;
      color: #7A7A7A;
      border-radius: 8px;
    }

    & .book-form-button-active {
      background: #FAA81F;
      color: black;
      cursor: pointer;
    }
  }
}

@media (max-width: 700px) {

  .book .book-form {
    top: unset;
    bottom: 0;
    right: 0;
    height: auto;
    width: 100%;
    padding: 24px;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    overflow: scroll;

    & .book-form-header {
      font: 700 24px/26px Inter;
    }

    & .book-form-input-caption {
      margin-top: 24px;
      font: 600 16px/14px Inter;
      letter-spacing: -0.03em;
    }

    & .book-form-input {
      box-sizing: border-box;
      margin-top: 16px;
      padding: 10px 16px;
      width: 100%;
      font: 500 16px/22px Inter;
      letter-spacing: -0.06em;
    }

    & .book-form-text,
    & .book-form-rules {
      max-width: 100%;
      margin-top: 24px;
      line-height: 1.36;
    }

    & .book-form-text {
      font-size: 14px;
    }

    & .book-form-rules {

      input {
        margin-top: 3px;
      }
    }

    & .book-form-text-confirm {
      margin-top: 8px;
      max-width: 100%;
      font: 500 14px/19px Inter;
    }

    & .book-form-button {
      margin-top: 24px;
      height: 42px;
      width: 100%;
      font: 600 14px/14px Inter;
      border-radius: 8px;
      transform: skewX(-3.5deg);

      & span {
        transform: skewX(3.5deg);
      }
    }
  }
}
